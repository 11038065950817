<template>
    <el-dialog
        :title="$t('employees.add_damage')"
        :visible.sync="modalAddDamage"
        :before-close="closeModal"
        width="600px"
        top="40px"
        :close-on-click-modal="false"
        @open="modalOpen"
        @closed="$emit('closed')"
    >
        <div v-loading="$wait.is('loading.add_damage')" class="flex justify-center my-5 px-12">
            <el-form ref="form" :model="formData" label-position="top" :rules="rules" class="w-full">
                <el-form-item :label="$t('employees.status')" class="w-full">
                    <el-select v-model="formData.status" class="w-full">
                        <el-option :value="0" :label="$t('employees.open')" />
                        <el-option :value="1" :label="$t('employees.solved')" />
                    </el-select>
                </el-form-item>

                <el-form-item :label="$t('employees.description')" prop="description" class="w-full">
                    <el-input
                        v-model="formData.description"
                        type="textarea"
                        :rows="3"
                    />
                </el-form-item>

                <el-form-item :label="$t('employees.amount')" prop="amount" class="w-full">
                    <money v-model="formData.amount" class="el-input__inner" v-bind="money" />
                </el-form-item>

                <el-form-item prop="addressUuid" class="w-full">
                    <ClientsAddressesDropdown ref="clientsAddressesDropdown" @change="formData.addressUuid = $event.uuid" />
                </el-form-item>
            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeModal">{{ $t('common.cancel') }}</el-button>
            <el-button type="primary" :loading="$wait.is('loading.add_damage')" @click="add">{{ $t('common.add') }}</el-button>
        </span>
    </el-dialog>
</template>
<script>
import Vue from 'vue';
import { Money } from 'v-money';

Vue.use(Money);

export default {
    components: {
        Money,
        ClientsAddressesDropdown: () => import(/* webpackChunkName: "ClientsAddressesDropdown" */ '@/components/ClientsAddressesDropdown'),
    },

    props: {
        modalAddDamage: {
            type:    Boolean,
            default: false,
        },
        employeeUuid: {
            type:    String,
            default: null,
        },
    },

    data() {
        return {
            addressesData: [],
            money:         {
                decimal:   ',',
                thousands: '',
                precision: 2,
                masked:    true,
            },
            formData: {
                status:      0,
                amount:      0,
                description: '',
                addressUuid: null,
            },
            rules: {
                amount:      [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                description: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                addressUuid: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
            },
            selectedAddress: '',
        };
    },

    methods: {
        modalOpen() {
            this.formData = {
                status:      0,
                amount:      0,
                description: '',
                addressUuid: null,
            };
        },

        async add() {
            await new Promise(resolve => this.$refs.form.validate(valid => valid && resolve()));
            await new Promise(resolve => this.$refs.clientsAddressesDropdown.$refs.formData.validate(valid => valid && resolve()));

            this.$wait.start('loading.add_damage');

            try {
                await axios.post(`${process.env.VUE_APP_CORE_API_URL}/clients/damages`, {
                    status:        this.formData.status,
                    amount:        parseInt(this.formData.amount.replace(',', ''), 10),
                    description:   this.formData.description,
                    address_uuid:  this.formData.addressUuid,
                    employee_uuid: this.employeeUuid,
                });

                this.$wait.end('loading.add_damage');
                this.$notify.success({ title: 'Success' });
                this.$emit('refresh');
                this.closeModal();
            } catch (error) {
                this.$wait.end('loading.add_damage');
            }
        },

        closeModal() {
            this.$refs.clientsAddressesDropdown.resetSelection();

            this.formData = {
                status:      0,
                amount:      0,
                description: '',
                addressUuid: null,
            };

            this.$emit('close');
        },
    },
};
</script>
