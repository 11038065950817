<template>
    <el-dialog
        :title="$t('employee_details.assign_category')"
        :visible.sync="modalAddServiceCategory"
        :before-close="closeModal"
        width="600px"
        top="40px"
        :close-on-click-modal="false"
        @open="modalOpen"
    >
        <div v-loading="$wait.is('loading.add.service.category')" class="flex justify-center my-5 px-12">
            <el-form ref="form" :model="formData" label-position="top" :rules="rules" class="w-full">
                <el-form-item :label="$t('employee_details.category')" prop="categoryUuid" class="w-full">
                    <el-select v-model="formData.categoryUuid" placeholder="Select" class="w-full">
                        <el-option
                            v-for="item in categoriesData"
                            :key="item.value"
                            :label="item.name"
                            :value="item.uuid"
                        />
                    </el-select>
                </el-form-item>
            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeModal">{{ $t('common.cancel') }}</el-button>
            <el-button type="primary" :loading="$wait.is('loading.add.service.category')" @click="add">{{ $t('common.add') }}</el-button>
        </span>
    </el-dialog>
</template>
<script>
export default {
    props: {
        modalAddServiceCategory: {
            type:    Boolean,
            default: false,
        },
        employeeUuid: {
            type:    String,
            default: null,
        },
        servicesCategoryData: {
            type:    Array,
            default: () => [],
        },
    },

    data() {
        return {
            categoriesData: [],
            formData:       {
                categoryUuid: '',
            },
            rules: {
                categoryUuid: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
            },
        };
    },

    methods: {
        modalOpen() {
            this.formData = {
                categoryUuid: '',
            };
            this.getCategories();
        },

        async getCategories() {
            this.$wait.start('loading.add.service.category');
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/services/categories`);
            this.categoriesData = data.filter(ar => !this.servicesCategoryData.find(rm => (rm.name === ar.name)));
            this.$wait.end('loading.add.service.category');
        },

        async add() {
            await new Promise(resolve => this.$refs.form.validate(valid => valid && resolve()));

            this.$wait.start('loading.add.service.category');

            try {
                await axios.post(`${process.env.VUE_APP_CORE_API_URL}/employees/employees/${this.employeeUuid}/assigned_categories`, {
                    category_uuid: this.formData.categoryUuid,
                });

                this.$wait.end('loading.add.service.category');
                this.$notify.success({ title: 'Success' });
                this.$emit('refresh');
                this.closeModal();
            } catch (error) {
                this.$wait.end('loading.add.service.category');
            }
        },

        closeModal() {
            this.formData = {
                categoryUuid: '',
            };
            this.$emit('close');
        },
    },
};
</script>
